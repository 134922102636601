<template>
  <div class="modal-container">
    <div v-if='loggedIn && !newUser' class="login-modal region-promo modal">
      <h1>We're not quite there yet!</h1>
      <h2>Want a text when we arrive in {{regionInfo.text}}?</h2>
      <div v-if="showSpinner" class="loader"></div>
      <button @click="signUp" class="big-button">Sign up</button>
      <button @click="hideModal" class="small-button">Let me choose a different location</button>
     </div>
    <div v-else class="login-modal region-promo modal">
      <h1>We're not quite there yet!</h1>
      <h2>But we'd love to send you <b>40% any plan</b> once we support {{regionInfo.name}}.</h2>
      <div v-if="showSpinner" class="loader"></div>
      <PhoneAuth @authenticated="wasAuthenticated" v-bind:region-promo='regionInfo'/>
    </div>
    <div class="modal-backdrop" @click="hideModal"></div>
  </div>
</template>

<script>
const PhoneAuth = () => import('./phone-auth.vue');
import axios from 'axios';

export default {
  components: { PhoneAuth },
  props: ['regionInfo', 'location',],
  name: 'RegionPromoModal',
  data: function () {
    return {
      loggedIn: null,
      showSpinner: false,
      newUser: null,
    }
  },
  mounted() {
    if (this.$store.state.phoneNumber != '') {
      // you're logged in 
      this.newUser = false;
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
  },
  methods: {
    hideModal () {
      this.$emit('regionPromoDismissed', true, this.newUser);
    },
    wasAuthenticated(newUser) {
      // when they successfully sign up or sign in
      this.newUser = newUser;
      this.loggedIn = true;
      if (this.newUser == true) {
        setTimeout(() => {
          console.log("region-promo: delayed didRegister/RegionPromoSignedUp");          
          this.$emit('regionPromoDismissed', true, newUser);

        }, 1000);
      }
    },
    signUp() {
      // for an existing user to register for notifications
      console.log("click");

      axios.post('region/notify/', {
          region_info: this.regionInfo,
          location: this.location,
        }).then((response) => {
          this.pendingConfirmation = true;
          this.loading = false;

          this.$nextTick(() => {
            this.$emit('regionPromoDismissed', true, false);
          });
          
        }).catch(error => {
          console.log(error);
          this.loading = false;
          this.errorMessage = error.response.data;
          this.$emit('regionPromoDismissed', false, false);
        })


    }
  },
}
</script>

<style lang="scss" scoped>

.modal-backdrop {
  //
}


.login-modal {
  // background: yellow;
}

.region-promo {
  h1 {
    font-family: $header-font;
  }
  h2 {
    font-weight: 300;
  }
  
}

</style>
